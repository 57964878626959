
.group__tree {
  &.active {
    background-color: var(--gray-400);
    border-radius: 0.5rem;

    .chevron__icon {
      color: var(--gray-600);
    }
  }
}

.collapse__border__left {
  border-left: 2px solid var(--gray-300);
}

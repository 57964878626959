
@import "@/assets/scss/utils/b-table-redesign";

.group__tree__section {
  background-color: var(--gray-200);
  width: fit-content;
  border-radius: 2rem;
  padding: 1rem 2rem;
  min-width: 20rem;
}
